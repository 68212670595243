$icon-font-path: '../fonts/';

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* Space out content a bit */
body {
  padding-top: 20px;
  padding-bottom: 20px;
  background-image: url(/images/vertikalgradering.jpg); 
  background-repeat:repeat-x
}

.nmbforside { 
  text-align: center; 
  background-color: #fff; 
  border: 1px #eee solid;
  padding: 0 1em
}
.nmbunderside { 
  //text-align: center; 
  background-color: #fff; 
  border: 1px #eee solid;
  padding: 0 1em
}


/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
  padding-left: 15px;
  padding-right: 15px;
}

/* Custom page header */
.header {
  background-color: #0099ff;
  border-bottom: 1px solid #0099ff;
  padding: 1em;
  text-align: center; 
  border-radius: 1em;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;

  img {
    max-width: 200px;
  }
}

/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #0099ff;
}

.container-narrow > hr {
  margin: 30px 0;
}

/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  .btn {
    font-size: 21px;
    padding: 14px 24px;
  }
}

/* Supporting marketing content */
.marketing {
  margin: 40px 0;
  p + h4 {
    margin-top: 28px;
  }
}

.navbar {
  border-bottom: 1px #ccc solid;
  font-size: 1em;
  //background-color: #ccc;
  padding: 1em 0;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
}
nav li {
 // border-bottom: 1px #fff solid;
  //border-radius: 5px;
  //margin:  0.4 1em;
  //font-size: 0.8em
}
nav ul {
    padding: 10px;
  }
nav li {
  list-style: none;
  margin-left: 0.8em;
  text-transform: uppercase;
  line-height: 1.2em;
  margin-top: 0.6em;
  //border-top: 1px #ccc solid;
  border: 2px #ccc solid;
  padding: 0.6em 1em;
  //float: left;
}
.navbar-brand {
  float: none;
  padding: 0;
  //padding: 0 0 0 8px;
}
.navlinkskiller {
  display: none;
  }

.header {
  h5 {
    margin: 16px 0;
    font-size: 0.85em
  }
  img {
    margin-right: 1em;
  }
}

.NMBingress {
  padding: 1em 0 2em 0;
  font-size: 1.6em;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif
}

.NMBforsidebilde {
  margin-bottom: 2em;
}
img.NMBforsidebilde {
    width: 100%;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  body {
        background-image: url(/images/vertikalgradering.jpg); 
        background-repeat:repeat-x
      }
  .container {
    max-width: 730px;
  }

  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-left: 0;
    padding-right: 0;
  }

  /* Space out the masthead */
  .header {
    margin-bottom: 30px;

    img {
      max-width: 250px;
    }
  }

  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }

  .navbar {
    /*border-bottom: 1px #ccc solid;
    font-size: 1em;
    background-color: #ccc;
    padding: 1em; */
  }
.navbar-brand {
    float: none;
    padding-right: 0;
    display: none
  }
  nav ul {
    padding: 0px;
  }
  nav li {
    border: none;
    padding: 0;
    float: left;
  }
  .navlinkskiller {
        margin-left: 1em;
        display: inline-block;
        margin-left: 0.8em;
  }
  img.NMBforsidebilde {
    width: 80%;
}
}

  /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {

      .container {
        max-width: 990px;
      }
      .navlinkskiller {
        display: inline-block;
      }
      .header img {
        max-width: 300px;
      }
    }

    /* Large Devices, Wide Screens */
    @media only screen and (min-width : 1200px) {
      .container {
        max-width: 1198px;
      }
      nav .container-fluid {
        margin: 0 0 0 95px;
      }
      
      .navbar {

      }
      .header img {
        max-width: 400px;
      }
      img.NMBforsidebilde {
          width: 80%;
      }
    }